import React from 'react';
import { Switch } from 'react-router-dom';
import FormOrchestrator from 'components/form-orchestrator';
import Reports from 'components/reports';
import Whitelist from 'components/whitelist';
import ProtectedRoute from './ProtectedRoute';

const Router = ({
    roles = [],
    distinguishedName
}) => {
    const isAdmin = roles.includes('admin');
    const isValidUser = distinguishedName &&
        distinguishedName
            .split(',')
            .filter(element => element === 'OU=External' || element === 'OU=Normal')
            .length > 0;

    return (
        <>
            <Switch>
                <ProtectedRoute path="/" component={FormOrchestrator} canAccess={isValidUser} exact />
                <ProtectedRoute path="/reports" component={Reports} canAccess={isAdmin} />
                <ProtectedRoute path="/edit" component={Whitelist} canAccess={isAdmin} />
            </Switch>
        </>
    );
};

export default Router;
