import React, { useState, useEffect } from 'react';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { trackPromise } from 'react-promise-tracker';
import _ from 'lodash';
import './index.scss';
import { Link } from 'react-router-dom';
import Textarea from 'components/textarea';

const apiName = 'UrlShortenerApi';
const whitelistEndpoint = '/whitelist';
const updateEndpoint = whitelistEndpoint + '/update';
// const migrateEndpoint = whitelistEndpoint + '/migrate';

const uppercaseRegex = new RegExp('[A-Z]', 'g');
const errorMsg = 'Only domains without the pathname and small letters are eligible';

const Whitelist = () => {
    const [items, setItems] = useState([]);
    const [databaseItems, setDatabaseItems] = useState([]);
    const [errors, setErrors] = useState([]);
    const [cancelFlag, setCancelFlag] = useState(false);
    
    const whitelistPostRequest = async (endpoint, newDomains, removedDomains) => {
        const user = await Auth.currentAuthenticatedUser();
        const payload = {
            email: user.attributes.email,
            domains: {
                new: [],
                removed: []
            }
        };
        if (newDomains) {
            payload.domains.new = [...newDomains];
        }
        if (removedDomains) {
            payload.domains.removed = [...removedDomains];
        }
        
        trackPromise(API
            .post(apiName, endpoint, {
                body: payload
            })
            .then((response) => {
                console.log('Whitelist API post response', response);
                handleApiResponse(response);
            }))
            .catch((error) => {
                console.log('Whitelist API post error', error);
            });
    }

    const handleApiResponse = (response) => {
        const sortedResponseItems = _.sortBy(response, function(item){
            return new Date(item.CreateDate);
        })
        const domains = sortedResponseItems.map(i => i.Domain);
        setItems(domains);
        setDatabaseItems(domains);
    }

    const isMalformedOrContainsPathname = (domain) => {
        try {
            const url = new URL(domain);
            const pathName = url.pathname;
            return pathName !== '/'; 
        } catch (error) {
            const urlError = `${domain} is an invalid URL`;
            console.log(`${urlError},`, error.message);
            return true;
        }

    }

    const validateDomainEntries = (domains) => {
        const detectedErrors = [];
        const uppercaseLettersDetected = domains.some(d => uppercaseRegex.test(d));       
        const invalidEntriesDetected = domains.some(d => isMalformedOrContainsPathname(d));

        if (uppercaseLettersDetected || invalidEntriesDetected) {
            detectedErrors.push(errorMsg);
        }
        return detectedErrors;
    }

    useEffect(() => {
        const getWhitelistedDomains = () =>
            trackPromise(API
                .get(apiName, whitelistEndpoint)
                .then((response) => {
                    console.log('Whitelist API get response', response);
                    handleApiResponse(response);
                }));
        getWhitelistedDomains();
    }, []);
    
    const domainUpdateHandler = (domains) => {
        setErrors([]);
        setItems(domains);
    }

    const saveHandler = async () => {
        const newItems = items.filter(i => !databaseItems.includes(i));
        console.log('saveHandler new items', newItems);
        const removedItems = databaseItems.filter(d => !items.includes(d));
        console.log('saveHandler removed items', removedItems);

        const newItemErrors = validateDomainEntries(newItems);
        if (newItemErrors.length > 0) {
            setErrors(newItemErrors);
            return;
        }
        if (newItems.length > 0 || removedItems.length > 0) {
            await whitelistPostRequest(updateEndpoint, newItems, removedItems); 
        }   
    };

    const cancelChanges = () => {
        setErrors([]);
        setItems(databaseItems);
        setCancelFlag(true);
    }

    const cancelCompleteHandler = () => {
        setCancelFlag(false);
    }

    // const migrateFromFile = async () => {
    //     await whitelistPostRequest (migrateEndpoint);
    // }

    return (
        <div className={"whitelist"}>
            <h3 className={"headline"}>Whitelist domain</h3>
            <h4 className={"leadText"}>URL to shorten must start with or match exactly one of the entries in the list</h4>
            <Textarea 
                placeholder={"Enter one per line"} 
                items={items}
                databaseItems={databaseItems}
                onContentUpdate={domainUpdateHandler}
                cancelFlag={cancelFlag}
                onCancelComplete={cancelCompleteHandler}
                errors={errors}
            />
            <div className={"buttonContainer"}>
                <button onClick={cancelChanges} className={"actionButton cancel"}>Cancel</button>
                <button onClick={saveHandler} className={"actionButton save"}>Save</button>
                {/* You can use the Migrate button in case if you want to allow updating the domain whitelist from file */}      
                {/* <button onClick={migrateFromFile} className={"actionButton migrate"}>Migrate from file</button> */}
            </div>
            <div className={"linkButtonContainer"}>
                <Link to="/" className={"linkButton"}>View page</Link>
            </div>
        </div>
    )
};

export default Whitelist;
