import React, { Component } from 'react';
import ContentEditable from "react-contenteditable";
import _ from 'lodash';
import './index.scss';

class Textarea extends Component {
    constructor(props) {
        super(props);
        this.state = { html: '' };
    }

	componentDidUpdate = (prevProps) => {
        const itemsWereReloadedFromDatabase = !_.isEqual(prevProps.databaseItems, this.props.databaseItems);
        const cancelCurrentChanges = this.props.cancelFlag === true;
        if (itemsWereReloadedFromDatabase) {
            console.log('componentDidUpdate: items were reloaded', itemsWereReloadedFromDatabase);
            this.setState({ html: this.createHtmlFromProps() });
        }
        if (cancelCurrentChanges) {
            console.log('componentDidUpdate: cancel current changes', cancelCurrentChanges);
            this.setState({ html: this.createHtmlFromProps() });
            this.props.onCancelComplete();
        }
    }

    createHtmlFromProps = () => {
        let html = "<p class='placeholder'>" + this.props.placeholder + "</p>";
        for (let index = 0; index < this.props.items.length; index++) {
            const element = this.props.items[index];
            html += "<p>" + element + "</p>";
        }
        return html;
    }

    getDataFromHtml = (html) => {
        const textContent = html.replace(/<[^>]+>/g, ' ');
        return textContent.replace(this.props.placeholder, '').split(' ').filter(i => i !== "");
    }

    handleChange = (evt) => {
        const value = evt.target.value.replace('&nbsp;', '');
        this.setState({ html: value });

        const domains = this.getDataFromHtml(value);
        this.props.onContentUpdate(domains);
    };

    render () {
        return (
            <div className="container">
                <ContentEditable
                    className={`textarea ${this.props.errors && this.props.errors.length > 0 ? 'redBottomBorder' : ''}`}
                    html={this.state.html}
                    onChange={this.handleChange}
                />
                {
                    this.props.errors && this.props.errors.length > 0  && this.props.errors.map((error, index) => {
                        return <span key={index} className="errorMessage">{error}</span>
                    })
                }
            </div>  
        );
    };
}

export default Textarea;
