import React from 'react';
import './index.scss';
import logo from './logo.png';

const Header = () => (
    <header className="header">
        <a href="/">
            <img src={logo} className="logo" alt="ABB logo" />
        </a>
        <span className="app-name">URL shortener</span>
    </header>
);

export default Header;
