import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import loader from './abb-loader.gif';
import './index.scss';

const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        promiseInProgress &&
        <div className="loading">
            <img src={loader} alt="Loading"></img>
        </div>
    )
};

export default LoadingIndicator;
