import isURL from 'validator/lib/isURL';

const urlValidationOptions = {
    require_protocol: true,
    protocols: ['http','https'],
    allow_underscores: true
};

const validateUrl = (url) => isURL(url, urlValidationOptions);

export default validateUrl;
