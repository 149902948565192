import React, { useState } from 'react';
import TextField, { HelperText, Input } from '@material/react-text-field';
import Button from 'components/button';

const EnterUrl = (props) => {

    const createState = (targetUrl) => ({
        url: targetUrl
    });

    const [state, setState] = useState(createState(props.targetUrl));

    const handleChange = (event) => {
        const targetUrl = event.target.value.trim();
        const newState = createState(targetUrl);
        setState(newState);

        if (targetUrl && props.onValidUrl) {
            props.onValidUrl(targetUrl);
        }
    };

    return (
        <>
            <h3>
                Paste your target URL below to generate a shortcut URL
            </h3>
            <div className="container text-field">
                <TextField
                    label="Enter URL"
                    helperText={
                        <HelperText persistent>
                            The pattern for all shortcut URLs is: <strong>"to.abb/ud4bd8d2"</strong>. More information about this tool can be found on the&nbsp;
                                <a
                                    className="link"
                                    target="_blank"
                                    href="https://wiki.inside.abb.com/corporate-communications/branded-url-shortener/"
                                    rel="noopener noreferrer">
                                    ABB Wiki
                                </a>.
                        </HelperText>
                    }>
                    <Input
                        id="target-url"
                        name="target-url"
                        autoComplete="on"
                        autoFocus
                        value={state.url}
                        onChange={handleChange} />
                </TextField>
            </div>
            <div>
                <Button
                    id="shorten"
                    disabled
                    type="submit"
                    value="Shorten"
                />
            </div>
        </>
    );
}

export default EnterUrl;
