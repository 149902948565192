import React from 'react';
import API from '@aws-amplify/api';
import { trackPromise } from 'react-promise-tracker';
import downloadFile from 'components/download-file';
import Button from 'components/button';

const shortcuts = () =>
    trackPromise(API
        .get('UrlShortenerApi', '/report')
        .then((response) => downloadFile(response.report, 'shortcuts.csv')));

const duplicates = () =>
    trackPromise(API
        .get('UrlShortenerApi', '/duplicates-report')
        .then((response) => downloadFile(response.report, 'duplicates.csv')));

const Reports = () => (
    <div>
        <h3>Download reports</h3>
        <div className="container">
            <Button
                type="button"
                value="Shortcuts"
                onClick={shortcuts}
            />
        </div>
        <div className="container">
            <Button
                type="button"
                value="Duplicates"
                onClick={duplicates}
            />
        </div>
    </div>
);

export default Reports;
