import React, { useState } from 'react';
import ShortenUrl from 'components/shorten-url';
import EnterUrl from 'components/enter-url';
import CopyUrl from 'components/copy-url';
import ShortcutUrlCopied from 'components/shortcut-url-copied';

export const Screen = {
    ENTER: 0,
    SHORTEN: 1,
    COPY: 2,
    COPIED: 3
};

const initialState = {
    screen: Screen.ENTER,
    targetUrl: '',
    shortcutUrl: undefined
};

const FormOrchestrator = () => {

    const [state, setState] = useState(initialState);

    const resetState = () => setState(initialState);

    const setTargetUrl = (targetUrl) =>
        setState({
            targetUrl: targetUrl,
            screen: Screen.SHORTEN
        });
    
    const setShortcutUrl = (shortcutUrl) =>
        setState({
            shortcutUrl: shortcutUrl,
            screen: Screen.COPY
        });

    const shortcutUrlCopied = () =>
        setState({
            screen: Screen.COPIED
        });

    return ({
        [Screen.ENTER]: <EnterUrl
            targetUrl={state.targetUrl}
            onValidUrl={setTargetUrl}
        />,
        [Screen.SHORTEN]: <ShortenUrl
            targetUrl={state.targetUrl}
            onEmptyUrl={resetState}
            onShortcutUrlReady={setShortcutUrl}
        />,
        [Screen.COPY]: <CopyUrl
            shortcutUrl={state.shortcutUrl}
            onShortcutUrlCopied={shortcutUrlCopied}
        />,
        [Screen.COPIED]: <ShortcutUrlCopied onAnotherUrlShortcutRequested={resetState} />
    })[state.screen];
}

export default FormOrchestrator;
