import React from 'react';
import Button from 'components/button';

const ShortcutUrlCopied = ({ onAnotherUrlShortcutRequested }) => (
    <>
        <h3>
            Your shortcut URL has successfully been copied to the clipboard
        </h3>
        <Button
            id="shorten-another-url"
            type="button"
            value="Shorten another URL"
            onClick={onAnotherUrlShortcutRequested}
        />
        <p>
            Your short URL will be active shortly. You will receive a confirmation e-mail once it is working.
        </p>
    </>
);

export default ShortcutUrlCopied;
