import React, { useRef } from 'react';
import TextField, { Input } from '@material/react-text-field';
import Button from 'components/button';
import copy from 'copy-to-clipboard';

const CopyUrl = (props) => {
    const urlInput = useRef(null);

    const prepareToCopy = () => {
        urlInput.current.inputElement_.current.focus();
        urlInput.current.inputElement_.current.select();
    };

    const copyToClipboard = () => {
        prepareToCopy();
        const copied = copy(urlInput.current.inputElement_.current.value);

        if (copied && props.onShortcutUrlCopied) {
            props.onShortcutUrlCopied();
        }
    };

    const handleFocus = (event) => event.target.select();

    return (
        <>
            <h3>
                Click the "Copy" button to copy your shortcut URL to the clipboard
            </h3>
            <div className="container text-field">
                <TextField>
                    <Input
                        id="shortcut-url"
                        autoFocus
                        ref={urlInput}
                        onFocus={handleFocus}
                        handleFocusChange={prepareToCopy}
                        value={props.shortcutUrl}
                        onChange={() => { }}
                        onCopy={copyToClipboard}
                        onCut={copyToClipboard} />
                </TextField>
            </div>
            <div>
                <Button
                    id="copy-shortcut-url"
                    type="button"
                    value="Copy"
                    onClick={copyToClipboard}
                />
            </div>
        </>
    );
}

export default CopyUrl;
