/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "UrlShortenerApi",
            "endpoint": "https://rniaxjql1i.execute-api.eu-west-1.amazonaws.com/stage",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:cfd0edb3-d4cc-4787-bfc9-f2416076eb89",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_sc1C4LqGN",
    "aws_user_pools_web_client_id": "2alfmet1jpb3oplld23034ad9d",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "url-shortener-amp-20191105205326-hostingbucket-stage",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://url-shortener-amp-20191105205326-hostingbucket-stage.s3-website-eu-west-1.amazonaws.com",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Shortcut-stage",
            "region": "eu-west-1"
        },
        {
            "tableName": "Whitelist-stage",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
