import React, { useState } from 'react';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { trackPromise } from 'react-promise-tracker';
import TextField, { HelperText, Input } from '@material/react-text-field';
import validateUrl from 'validateUrl';
import Button from 'components/button';
import './index.scss';

const ShortenUrl = (props) => {

    const createState = (targetUrl) => {
        const url = targetUrl ? targetUrl : '';
        const isInvalidUrl = !validateUrl(url);

        return {
            url: url,
            isInvalidUrl: isInvalidUrl,
            isNotWhitelisted: false,
            isSubmitting: false
        };
    };

    const [state, setState] = useState(createState(props.targetUrl));

    const handleChange = (event) => {
        const targetUrl = event.target.value.trim();
        const newState = createState(targetUrl);
        setState(newState);

        if (!targetUrl && props.onEmptyUrl) {
            props.onEmptyUrl();
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setState(previous => ({ ...previous, ...{ isSubmitting: true } }));
        const user = await Auth.currentAuthenticatedUser();

        trackPromise(
            API
                .post('UrlShortenerApi', '/shortcut-url', {
                    body: {
                        targetUrl: state.url,
                        email: user.attributes.email
                    }
                })
                .then((response) => props.onShortcutUrlReady(`https://${response.ShortcutUrl}`))
                .catch((error) => {
                    if (error.response.status === 400) {
                        setState(previous => ({ ...previous, ...{ isNotWhitelisted: true } }));
                    }
                })
        );
    };

    const renderHelperText = () => (
        <HelperText isValidationMessage validation>
            {
                state.isInvalidUrl && <span id="invalid-url-message">Invalid URL</span>
            }
            {
                state.isNotWhitelisted &&
                <span id="not-whitelisted-message">
                    The target URL you chose is not eligible for a shortcut URL. A list of all eligible domains can be found on the&nbsp;
                    <a
                        target="_blank"
                        href="https://wiki.inside.abb.com/corporate-communications/branded-url-shortener/"
                        rel="noopener noreferrer">
                        ABB Wiki
                    </a>
                    .
                </span>
            }
        </HelperText>);

    const onFocus = event => event.target.selectionStart = event.target.value.length; // this prevents IE to move cursor to the begining of input field after focus 

    return (
        <form onSubmit={handleSubmit}>
            <h3>
                Click the "Shorten" button to generate your shortcut URL
            </h3>
            <div className="container text-field">
                <TextField
                    label="Enter URL"
                    helperText={renderHelperText()}>
                    <Input
                        id="target-url"
                        name="target-url"
                        autoComplete="on"
                        autoFocus
                        onFocus={onFocus}
                        value={state.url}
                        onChange={handleChange}
                        isValid={!state.isInvalidUrl && !state.isNotWhitelisted} />
                </TextField>
            </div>
            <div>
                <Button
                    id="submit"
                    disabled={state.isSubmitting || state.isInvalidUrl || state.isNotWhitelisted}
                    type="submit"
                    value="Shorten"
                />
            </div>
        </form>
    );
}

export default ShortenUrl;
