import React from 'react';
import { Route } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, canAccess, ...rest }) => (
    <Route {...rest} render={(props) => (
        canAccess === true
            ? <Component {...props} />
            : <h1 className="error-message">Sorry, you are not allowed to access this page.</h1>
    )} />
);

export default ProtectedRoute;
